body {
  font-family: var(--fontfamily-body);
  font-size: var(--fontsize-body);
  line-height: var(--lineheight-body);
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--fontfamily-body);
  hyphens: auto;
}

h1 {
  font-size: var(--fontsize-h1);
  line-height: var(--lineheight-h1);
}

h2 {
  font-size: var(--fontsize-h2);
  line-height: var(--lineheight-h2);
}

h3 {
  font-size: var(--fontsize-h3);
  line-height: var(--lineheight-h3);
}

h4 {
  font-size: var(--fontsize-h4);
  line-height: var(--lineheight-h4);
}

h5 {
  font-size: var(--fontsize-h5);
  line-height: var(--lineheight-h5);
}

h6 {
  font-size: var(--fontsize-small);
  line-height: var(--lineheight-small);
  text-transform: uppercase;
}

p {
  font-size: var(--fontsize-body);
  line-height: var(--lineheight-body);
  hyphens: auto;
}
