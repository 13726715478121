.c-text {
  width: 100%;
  padding: 0 var(--grid-margin);

  @include tablet-landscape-up {
    display: flex;
    justify-content: flex-end;
  }
}

.c-text__content {
  width: 100%;

  @include tablet-landscape-up {
    width: grid-col(8, 12);
  }
}

.c-text__heading,
.c-text__text {
  width: 100%;
}

.c-text__heading {
  margin-bottom: 1rem;

  @include tablet-landscape-up {
    width: grid-col(1, 2);
    margin-bottom: 2rem;
  }
}

.c-text__text {
  p {
    margin-bottom: 1em;
  }

  a {
    color: var(--color-hover);
  }

  h5 {
    font-size: var(--fontsize-body);
    margin-bottom: 0.5rem;
  }

  @include tablet-landscape-up {
    columns: 2;
    column-gap: var(--grid-gutter);
  }
}
