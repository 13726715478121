.c-information {
  padding: 0 var(--grid-margin);

  @include tablet-landscape-up {
    display: flex;
  }
}

.c-information__primary {
  width: 100%;
  margin-bottom: 3.5rem;

  @include tablet-landscape-up {
    width: grid-col(4, 12);
    margin-bottom: 0;
  }
}

.c-information__primary__heading {
  margin-bottom: 1.5rem;
}

.c-information__primary__content {
  p {
    margin-bottom: 1em;
  }
}

.c-information__meta {
  width: 100%;
  margin-bottom: 2rem;

  @include tablet-landscape-up {
    width: grid-col(2, 12);
    margin-left: var(--grid-gutter);
  }
}

.c-information__meta__heading {
  margin: 1rem 0;

  @include tablet-landscape-up {
    margin: 1.75rem 0;
  }
}

.c-information__meta__content {
  p {
    margin-bottom: 1em;
    font-size: var(--fontsize-small);
  }
}
