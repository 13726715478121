.c-header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: pointer;
  z-index: z-index(overlay);
}

.c-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 4rem;
  padding: 0 var(--grid-margin);
  font-size: var(--fontsize-body);
  will-change: transform;
  transition: background-color 0.45s var(--animation-ease) 0.075s;
  z-index: z-index(menu);
  user-select: none;

  &.is-hidden {
    transform: translateY(-100%);
  }

  @include tablet-portrait-up {
    height: 6.25rem;
    font-size: var(--fontsize-h4);
  }
}

.c-header__title,
.c-header__tickets,
.c-header__menu {
  margin-top: -0.125em;
}

.c-header__title {
  width: grid-col(4, 12);
  margin-right: var(--grid-gutter);
}

.c-header__hours {
  display: none;

  @include tablet-landscape-up {
    width: grid-col(5, 12);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.c-header__menu {
  cursor: pointer;
}

.c-header__tickets {
  margin-right: 1.5rem;

  a {
    color: var(--color-text);
    text-decoration: none;
  }

  @include tablet-landscape-up {
    margin-right: 3rem;
  }
}

.c-header__hours,
.c-header__tickets a,
.c-header__menu {
  transition: color 0.2s;

  &:hover {
    color: var(--color-hover);
  }
}

.c-header__hours__button {
  transform-origin: center center;
  transition: transform 0.2s var(--animation-ease);
}

.is-open .c-header__hours__button {
  transform: rotate(-180deg);
}

.c-header__text span {
  display: none;
}

.c-header__nav {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  @include tablet-landscape-up {
    width: grid-col(3, 12);
  }
}
