.c-info {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  padding: 0 var(--grid-margin);

  @include tablet-portrait-up {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--grid-gutter);
  }

  @include tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 6rem;
  }
}

.c-info__block__media {
  margin-bottom: 1.5rem;
}

.c-info__block__title {
  margin-bottom: 1.5rem;
}

.c-info__block__content {
  p {
    margin-bottom: 1em;
  }

  a {
    color: var(--color-hover);
  }

  h5 {
    font-size: var(--fontsize-body);
    margin-bottom: 0.5rem;
  }
}

.c-info__block__cta {
  transition: color 0.2s;
}

.c-info__block__content + .c-info__block__cta {
  margin-top: 1em;
}

a.c-info__block:hover .c-info__block__cta {
  color: var(--color-hover);
}
