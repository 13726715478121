.c-articles {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.5rem;
  padding: 0 var(--grid-margin);

  @include tablet-portrait-up {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--grid-gutter);
    grid-row-gap: 3rem;
  }

  @include tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 5rem;
  }
}

.c-articles__item__title {
  margin-bottom: 0.5em;
}

.c-articles__item__media {
  margin-bottom: 1.25rem;
}

@include tablet-landscape-up {
  .c-articles__item {
    &:nth-child(8n + 1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &:nth-child(8n + 2) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(8n + 3) {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    &:nth-child(8n + 4) {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &:nth-child(8n + 5) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &:nth-child(8n + 6) {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    &:nth-child(8n + 7) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &:nth-child(8n + 8) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}
