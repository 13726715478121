.c-navigation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: pointer;
  z-index: z-index(overlay);
}

.c-navigation,
.c-navigation__child {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 1.5rem;
}

.c-navigation {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--color-text);
  color: var(--color-background);
  overflow-x: hidden;
  overflow-y: scroll;
  transform: translateX(100%);
  will-change: transform;
  z-index: z-index(navigation);
  user-select: none;

  a {
    color: var(--color-background);
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    width: calc(50% + var(--grid-gutter) / 2);
  }

  @include desktop-large-up {
    width: calc(100% * 5 / 12 + var(--grid-gutter) / 2);
  }
}

.c-navigation__close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  width: 100%;
  height: 4rem;
  padding: 0 var(--grid-margin);
  transform: translateX(-100%);
  z-index: z-index(toggle);
  pointer-events: none;

  p {
    font-size: var(--fontsize-body);
    margin-right: 0.5rem;
    cursor: pointer;
  }

  @include tablet-portrait-up {
    height: 6.25rem;

    p {
      margin-right: 0;
      font-size: var(--fontsize-h4);
    }
  }
}

.c-navigation__close__icon {
  position: absolute;
  right: 0;
  margin: 0.125rem var(--grid-margin) 0;
  will-change: transform;
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;

  @include tablet-portrait-up {
    margin: 0.25rem var(--grid-margin) 0;
    width: 2.25rem;
    height: 2.25rem;
  }

  @include desktop-up {
    width: 3rem;
    height: 3rem;
  }
}

.c-navigation__list,
.c-navigation__child {
  padding: 0 var(--grid-margin) 0;
  font-size: var(--fontsize-h4);
  line-height: var(--lineheight-h4);
  will-change: transform;
}

.c-navigation__list {
  transform-origin: center left;
  opacity: 1;

  @include tablet-portrait-up {
    margin-bottom: 2.5rem;
  }
}

.c-navigation__list__item,
.c-navigation__child__item {
  margin-bottom: 0.25em;

  @include tablet-portrait-up {
    margin-bottom: 0;
  }
}

.c-navigation__child {
  position: absolute;
  padding-top: 3.4rem;
  transform: translateX(30%);
  opacity: 0;
  pointer-events: none;

  @include tablet-portrait-up {
    padding-top: 7rem;
  }
}

.c-navigation__child__back {
  font-size: var(--fontsize-small);
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  @include tablet-portrait-up {
    font-size: var(--fontsize-body);
  }
}

.c-navigation__timetable {
  width: 100%;
  padding: 0 var(--grid-margin);
  margin-top: auto;
  font-size: var(--fontsize-small);

  p {
    font-size: var(--fontsize-small);
  }

  @include tablet-portrait-up {
    display: flex;
    flex-wrap: wrap;
  }

  @include tablet-landscape-up {
    display: none;
  }
}

.c-navigation__week {
  margin-bottom: 2rem;

  @include tablet-portrait-up {
    width: grid-col(7, 9);
  }
}

.c-navigation__week__heading,
.c-navigation__general__heading {
  margin-bottom: 0.75em;
}

.c-navigation__week__day {
  margin-right: var(--grid-gutter);
}

.c-navigation__week__item {
  display: flex;

  .c-navigation__week__day,
  .c-navigation__week__time {
    line-height: var(--lineheight-tall);
  }

  .c-navigation__week__day {
    width: grid-col(1, 4);
  }

  .c-navigation__week__time {
    width: grid-col(3, 4);

    span {
      margin-left: 0.25em;
      font-size: 0.75em;
      line-height: 1;
      vertical-align: super;
    }
  }

  @include tablet-portrait-up {
    .c-navigation__week__day {
      width: grid-col(3, 7);
    }

    .c-navigation__week__time {
      width: grid-col(4, 7);
    }
  }
}

.c-navigation__week__notes {
  margin-top: 1em;
}

.c-navigation__week__note {
  display: flex;
  margin-bottom: 1em;

  span {
    display: block;
    width: 1em;
    font-size: 0.75em;
    vertical-align: super;
  }
}

.c-navigation__general__hours,
.c-navigation__general__info {
  max-width: 25em;
}

.c-navigation__general__hours {
  margin-bottom: 1.5em;
}

.c-navigation__general__info {
  margin-bottom: 3em;
}

.c-navigation__general__link {
  font-size: var(--fontsize-body);
}

.c-navigation__lang {
  font-size: var(--fontsize-h4);
  margin: 0 0 2rem var(--grid-margin);
  z-index: 1;

  @include tablet-portrait-up {
    margin: 0 0 3rem var(--grid-margin);
  }

  @include tablet-landscape-up {
    margin: auto var(--grid-margin) 0;
  }
}
