.c-booking-mobile {
  position: fixed;
  left: 0;
  bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--fontsize-small);
  z-index: z-index(navigation);

  @include tablet-landscape-up {
    display: none;
  }
}

.c-booking-mobile__toggle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 var(--grid-margin);
  background-color: var(--color-black);
  cursor: pointer;
  z-index: 2;

  svg {
    width: 1rem;
    height: 1.125rem;
    fill: var(--color-background-darker);
    transform-origin: center center;
    transition: transform var(--animation-duration-short) var(--animation-ease);
  }
}

.c-booking-mobile__toggle__button {
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  transition: opacity 0.2s;

  p {
    font-size: var(--fontsize-small);
  }
}

.is-expanded .c-booking-mobile__toggle__button {
  opacity: 0.5;
}

.c-booking-mobile__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  padding: 0 var(--grid-margin) 4rem;
  will-change: transform;
  background-color: var(--color-black);
}

.c-booking-mobile__title {
  width: 100%;
  padding: 1rem 0;
}

.c-booking-mobile__datepicker {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.c-booking-mobile__dates {
  position: relative;
  margin-bottom: 1rem;
}

.c-booking-mobile__dates__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 3rem;
  padding: 0 1.5rem;
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  text-transform: capitalize;
  cursor: pointer;
  z-index: 3;

  svg {
    width: 1.125rem;
    height: 1.1875rem;
    fill: var(--color-background);
    transform-origin: center center;
    transform: rotate(-180deg);
    transition: transform 0.3s;
  }
}

.is-expanded .c-booking-mobile__toggle svg {
  transform: rotate(-180deg);
}

.is-open .c-booking-mobile__dates svg {
  transform: rotate(0);
}

.c-booking-mobile__dates__list {
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 0 0 2rem 2rem;
  cursor: pointer;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 1.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 1.5rem;
    pointer-events: none;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2rem;
    pointer-events: none;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
  }

  ul {
    padding-bottom: 2rem;
  }
}

.c-booking-mobile__dates__scrollcontainer {
  width: calc(100% + 20px);
  height: 15.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.c-booking-mobile__dates__track {
  position: absolute;
  top: 2rem;
  right: 8px;
  bottom: 2rem;
  width: 2px;
  background-color: var(--color-track);
  z-index: 2;
}

.c-booking-mobile__dates__scrollbar {
  position: absolute;
  top: 2rem;
  right: 8px;
  width: 2px;
  background-color: var(--color-background-darker);
  z-index: 2;
}

.c-booking-mobile__dates__item {
  padding: 1.25rem 1.5rem;
  transition: color 0.3s;
  overflow: hidden;
  text-transform: capitalize;
  cursor: pointer;

  &:first-child {
    margin-top: 1.5rem;
  }

  &:last-child {
    margin-bottom: 3rem;
  }

  &:hover {
    color: var(--color-background);
  }

  input[type="radio"] {
    position: absolute;
    width: 0;
    top: 0;
    left: -5000px;
    opacity: 0;
  }

  label {
    cursor: pointer;
  }
}

.c-booking-mobile__times {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.c-booking-mobile__times__time {
  position: relative;
  display: none;
  margin: 0 0.5rem 0.5rem 0;

  &.is-visible {
    display: block;
  }
}

.c-booking-mobile__time__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.125rem;
  height: 3rem;
  border: 2px solid var(--color-text);
  border-radius: 2rem;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}
