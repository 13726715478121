@font-face {
  font-family: "Sono Regular";
  src:
    url("../assets/fonts/Sono-Regular.woff2") format("woff2"),
    url("../assets/fonts/Sono-Regular.woff") format("woff"),
    url("../assets/fonts/Sono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
