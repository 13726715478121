.c-reservation {
  display: none;

  @include tablet-landscape-up {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 var(--grid-margin);
    width: 100%;
    height: 100px;
    background-color: transparent;
    font-size: var(--fontsize-small);
    z-index: z-index(toolbar);
    transition:
      background-color var(--animation-duration-medium)
      var(--animation-ease);

    &.is-fixed {
      position: fixed;
      top: 0;
      background-color: var(--color-black);
    }
  }
}

.c-reservation__text {
  font-size: var(--fontsize-small);
  width: grid-col(3, 12);
}

.c-reservation__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5rem;
  height: 3rem;
  margin-left: 0.5rem;
  font-size: var(--fontsize-small);
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  transition: color 0.3s, opacity 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}
