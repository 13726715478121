.c-footer {
  flex: 1 0 auto;
  padding: 0 var(--grid-margin) 1rem;
  background-color: var(--color-text);
  color: var(--color-background);

  p,
  a {
    line-height: var(--lineheight-tall);
  }

  a {
    color: var(--color-background);
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    p,
    a {
      line-height: inherit;
    }
  }
}

.single-program .c-footer {
  padding-bottom: 5rem;

  @include tablet-landscape-up {
    padding-bottom: 1rem;
  }
}

.is-reservation .c-footer {
  padding-bottom: 6rem;

  @include tablet-landscape-up {
    padding-bottom: 1rem;
  }
}

.c-footer__contact {
  width: 100%;
  padding-top: 1rem;

  @include tablet-landscape-up {
    width: grid-col(4, 12);
    margin-bottom: 3rem;
    padding-top: 5.5rem;
  }
}

.c-footer__list {
  margin-bottom: 2rem;

  @include tablet-landscape-up {
    margin-bottom: 3rem;
    padding-top: 5.75rem;
    display: flex;
    justify-content: space-between;
    width: grid-col(8, 12);
  }
}

.c-footer__contact__address {
  margin-bottom: 0.5em;
}

.c-footer__contact__location {
  display: inline-block;
  margin-bottom: 1em;
}

.c-footer__list__column {
  width: 100%;
  margin-bottom: 1em;

  @include tablet-landscape-up {
    width: grid-col(1, 2);
    margin-bottom: 0;

    ul {
      margin-bottom: 1em;
    }
  }
}

.c-footer__legal {
  width: 100%;

  @include tablet-landscape-up {
    display: flex;
    justify-content: space-between;
  }
}
