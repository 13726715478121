html,
body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: var(--color-background);

  &.is-locked {
    overflow: hidden;
  }
}

a {
  color: var(--color-text);
  text-decoration: none;
}

.l-main {
  flex: 1 0 auto;
  width: 100%;
  overflow-x: hidden;
}

.l-content {
  opacity: 0;
  padding-top: 7.5rem;
}

.l-section {
  margin-bottom: 4rem;

  &.l-section--single {
    margin-bottom: 0;
  }

  @include tablet-landscape-up {
    margin-bottom: 5.5rem;
  }
}

.single-article .l-section {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 4rem;
  }

  @include tablet-landscape-up {
    margin-bottom: 2.75rem;

    &:first-child {
      margin-bottom: 4rem;
    }

    &:last-child {
      margin-bottom: 5.5rem;
    }
  }
}

.o-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: pointer;
  z-index: z-index(overlay);
}
