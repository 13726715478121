.o-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  flex-shrink: 0;
  background-color: var(--color-text);
  border-radius: 50%;

  svg {
    fill: var(--color-background);
  }
}

.o-icon--dark {
  background-color: var(--color-background);

  svg {
    fill: var(--color-text);
  }
}

.o-icon--arrow svg {
  width: 1.125rem;
  height: 1.1875rem;
}

.o-icon--close svg {
  width: 0.8rem;
  height: 0.8rem;

  @include tablet-portrait-up {
    width: 1.1875rem;
    height: 1.1875rem;
  }
}

.o-icon--next {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-right: 0;

  svg {
    width: 0.4375rem;
    height: 0.625rem;
    stroke: var(--color-background);
    fill: none;
  }

  &.o-icon--dark svg {
    stroke: var(--color-text);
  }
}
