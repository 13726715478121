.c-newsletter {
  padding: 0 var(--grid-margin);
}

.c-newsletter__intro {
  width: 100%;
  margin-bottom: 1.75rem;

  h4 {
    margin-left: -0.075em;
    font-size: var(--fontsize-h1);
    line-height: var(--lineheight-h1);
  }

  @include tablet-landscape-up {
    width: grid-col(11, 12);
  }
}

.c-newsletter__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: -0.075em;

  input[type="text"],
  input[type="email"] {
    margin-bottom: 1.5rem;
    padding: 0;
    background-color: transparent;
    font-size: var(--fontsize-h1);
    line-height: 1;
    border-width: 0 0 2px 0;
    border-radius: 0;
    border-color: var(--color-text);
    color: var(--color-text);
    outline: none;

    @include tablet-landscape-up {
      margin-bottom: 2.5rem;
    }
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder {
    color: var(--color-hover);
  }

  button[type="submit"] {
    align-self: flex-start;
    display: inline;
    margin: 0;
    padding: 0 0 0 1.25em;
    border: none;
    background-color: transparent;
    font-size: var(--fontsize-body);
    line-height: 1.25;
    text-align: left;
    color: var(--color-text);
    transition: color 0.2s;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    button[type="submit"] {
      font-size: var(--fontsize-h4);
    }
  }
}

.c-newsletter__form__input {
  display: flex;
  flex-direction: column-reverse;

  label {
    opacity: 0;
    transform: translateY(5px);
    font-size: var(--fontsize-small);
    transition:
      opacity 0.3s var(--animation-ease),
      transform 0.3s var(--animation-ease);
  }

  input:not(:placeholder-shown) + label {
    opacity: 1;
    transform: translateY(0);
  }
}
