.c-program {
  position: relative;
  margin-bottom: 5.5rem;
}

.c-program__options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 6.25rem;
  margin-bottom: 1.5rem;
  padding: 0 var(--grid-margin);
  transition: background-color var(--animation-duration-medium);

  &.is-fixed {
    position: fixed;
    top: 0;
    background-color: #000;
    z-index: z-index(toolbar);
  }

  @include tablet-portrait-up {
    margin-bottom: 2rem;
    height: inherit;

    &.is-fixed {
      position: fixed;
      top: 0;
      height: 6.25rem;
      padding-top: 1.5rem;
      background-color: #000;
      z-index: z-index(toolbar);
    }
  }

  @include tablet-landscape-up {
    margin-bottom: 3rem;
  }

  @include desktop-up {
    height: 6.25rem;
    margin-bottom: 1rem;
    padding-top: 0;

    &.is-fixed {
      position: fixed;
      top: 0;
      padding-top: 0;
      background-color: #000;
      z-index: z-index(toolbar);
    }
  }
}

.c-program__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  height: 6.25rem;
  width: 100%;
  margin-bottom: 1.5rem;
  pointer-events: none;

  &.is-visible {
    position: relative;
  }

  @include tablet-portrait-up {
    top: -1.5rem;
    height: 10rem;
  }

  @include desktop-up {
    top: 0;
    height: 6.25rem;
  }
}

.c-program__datepicker {
  display: flex;
  width: 100%;

  @include tablet-portrait-up {
    margin-bottom: 1.5rem;
  }

  @include desktop-up {
    width: grid-col(1, 3);
    margin-bottom: 0;
  }
}

.c-program__datepicker__prev,
.c-program__datepicker__next {
  border: none;
  margin: 0;
}

.c-program__datepicker__prev {
  transform: rotateZ(-90deg);
}

.c-program__datepicker__next {
  transform: rotateZ(90deg);
}

.c-program__datepicker__date {
  display: block;
  flex: 1 1 50%;
  height: 3rem;
  margin: 0 0.625rem;
  border: none;
  border-radius: 1.5rem;
  background-color: var(--color-text);
  font-size: var(--fontsize-small);
  -moz-appearance: none;
  -webkit-appearance: none;
  color: var(--color-background-darker);

  &::first-letter {
    text-transform: uppercase;
  }

  @include tablet-portrait-up {
    font-size: var(--fontsize-body);
  }
}

.c-program__datepicker__prev,
.c-program__datepicker__next,
.c-program__datepicker__date {
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }
}

.c-program__filter {
  display: none;

  @include tablet-portrait-up {
    display: flex;
    align-items: center;
    user-select: none;
  }

  @include desktop-up {
    width: 50%;
    justify-content: flex-end;
  }
}

.c-program__filter__label {
  margin-right: 2.5rem;
  margin-bottom: 1rem;

  @include tablet-landscape-up {
    margin-bottom: 0;
  }
}

.c-program__filter__option {
  input[type="checkbox"] {
    position: absolute;
    width: 0;
    opacity: 0;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;

    &::before {
      content: "";
      box-sizing: border-box;
      display: block;
      flex-shrink: 0;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      border: 2px solid var(--color-text);
      border-radius: 50%;
      transition: border 0.2s var(--animation-ease), background-color 0.3s;
    }

    @include tablet-landscape-up {
      margin-bottom: 0;
    }
  }
}

.c-program__filter__option input[type="checkbox"]:checked + label::before {
  border-width: 1.5rem;
  background-color: var(--color-text);
}

.c-program__filter__option + .c-program__filter__option {
  margin-left: 2.5rem;
}

.c-program__anchor {
  display: none;

  @include tablet-portrait-up {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    min-width: 5.125rem;
    height: 3rem;
    border: 2px solid var(--color-text);
    border-radius: 2rem;
    font-size: var(--fontsize-small);
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: var(--color-hover);
    }
  }
}

.c-program__scrollto {
  display: none;

  @include desktop-up {
    position: absolute;
    top: 50%;
    right: var(--grid-margin);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    min-width: 5.125rem;
    height: 3rem;
    transform: translateY(-50%);
    border: 2px solid var(--color-text);
    border-radius: 2rem;
    font-size: var(--fontsize-small);
    background-color: transparent;
    color: var(--color-text);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s, color 0.2s;
    user-select: none;
    outline: none;

    &:hover {
      color: var(--color-hover);
    }
  }
}

.is-fixed .c-program__filter,
.is-fixed .c-program__anchor {
  opacity: 0;
  pointer-events: none;
}

.is-fixed .c-program__scrollto {
  opacity: 1;
  pointer-events: all;
}

.l-program-container {
  position: relative;
}

.c-program__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0 var(--grid-margin);

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: var(--grid-gutter);
    justify-items: stretch;
    transform-origin: center top;
  }

  @include tablet-portrait-up {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 3rem;
  }

  @include tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.c-program__itemwrapper {
  display: block;
  margin-bottom: 1.5rem;
}

.c-program__item__title {
  transition: color 0.2s;
}

.c-program__itemwrapper:hover .c-program__item__title {
  color: var(--color-hover);
}

.c-program__item {
  width: 100%;
  margin-bottom: 3rem;

  @supports (display: grid) {
    width: 100%;
  }

  @include tablet-portrait-up {
    width: calc(99.9999% * 6 / 12 - (var(--grid-gutter) * 6 / 12));

    @supports (display: grid) {
      width: 100%;
    }
  }

  @include tablet-landscape-up {
    width: calc(99.9999% * 4 / 12 - (var(--grid-gutter) * 4 / 12));

    @supports (display: grid) {
      width: 100%;
    }
  }
}

.c-program__item--empty {
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  height: 15.625rem;
  margin-bottom: 0;
}

.c-program__item__mediawrapper {
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-program__item__media {
  display: block;
}

.c-program__item__times {
  display: flex;
  flex-wrap: wrap;
}

.c-program__item__time a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 0.6rem 0;
  padding: 0 1em;
  min-width: 5.125rem;
  height: 3rem;
  border: 2px solid var(--color-text);
  border-radius: 2rem;
  font-size: var(--fontsize-small);
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: var(--color-hover);
  }
}

.c-program__item__type--soldout {
  color: var(--color-red);
}

.c-program__loader {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 8.5rem;
  height: 3.5rem;
  border-radius: 2rem;
  background-color: #000;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: z-index(modal);
  opacity: 0;
}

.o-loader {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 52px;
  transform: translate(-50%, -50%);
}

.o-loader__dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-text);
  animation: pulse 3s ease-in-out infinite;

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
