.c-single {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--grid-margin);

  &:nth-of-type(2n + 1) {
    flex-direction: row-reverse;
  }
}

.c-single__title {
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-single__media {
  width: 100%;
  margin-bottom: 1rem;

  @include tablet-landscape-up {
    width: grid-col(8, 12);
    margin-bottom: 0;
  }
}

.c-single__desc {
  width: 100%;

  a {
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    width: grid-col(4, 12);
    margin-left: var(--grid-gutter);

    a {
      font-size: var(--fontsize-body);
    }
  }
}

@include tablet-landscape-up {
  .c-single:nth-of-type(2n + 1) .c-single__desc {
    margin-right: var(--grid-gutter);
    margin-left: 0;
  }
}

.c-single__desc__text {
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 1em;
  }

  h5 {
    font-size: var(--fontsize-body);
    margin-bottom: 0.5em;
  }
}

.c-single__desc__meta {
  margin-bottom: 1.5rem;

  h5,
  p,
  a {
    font-size: var(--fontsize-small);
  }

  h5 {
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1em;
  }

  a {
    color: var(--color-hover);
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-text);
  }

  @include tablet-landscape-up {
    width: grid-col(3, 4);
  }
}
