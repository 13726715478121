@function grid-col($numCols, $totalCols) {
  @return calc(
    99.9999% * #{$numCols} / #{$totalCols} -
    (var(--grid-gutter) - var(--grid-gutter) * #{$numCols} / #{$totalCols})
  );
}

@mixin phablet-up {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet-portrait-up {
  @media (min-width: 609px) {
    @content;
  }
}

@mixin tablet-landscape-up {
  @media (min-width: 919px) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-large-up {
  @media (min-width: 1440px) {
    @content;
  }
}
