.c-form {
  position: relative;
  width: 100%;
  padding: 0 var(--grid-margin);

  form {
    display: flex;
    flex-wrap: wrap;
  }

  button,
  select {
    font-size: var(--fontsize-small);
  }

  .c-form__checkboxes,
  .c-form__input,
  .c-form__textarea,
  select {
    margin-bottom: 2rem;
  }

  .c-form__input,
  .c-form__textarea {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    label {
      opacity: 0;
      transform: translateY(5px);
      font-size: var(--fontsize-small);
      transition:
        opacity 0.3s var(--animation-ease),
        transform 0.3s var(--animation-ease);
    }

    input:not(:placeholder-shown) + label,
    textarea:not(:placeholder-shown) + label {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .c-form__title {
    width: 100%;
  }

  .c-form__checkboxes,
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    padding: 0;
    background-color: transparent;
    border-color: var(--color-text);
    border-width: 0 0 2px 0;
    border-radius: 0;
    font-size: var(--fontsize-body);
    color: var(--color-text);
    outline: none;
  }

  input[type="text"]::placeholder,
  input[type="number"]::placeholder,
  input[type="email"]::placeholder,
  input[type="tel"]::placeholder,
  textarea::placeholder {
    padding: 0;
    margin: 0;
    color: var(--color-hover);
    font-family: var(--fontfamily-body);
  }

  input[type="text"],
  input[type="number"],
  input[type="email"] {
    height: 2rem;
  }

  textarea {
    resize: none;
  }

  select {
    display: block;
    width: 100%;
    height: 3rem;
    padding: 0 0 0 1.5rem;
    border: none;
    border-radius: 1.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: var(--color-background-darker);
    outline: none;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 14.879l6.293-6.293L17.707 10 9 18.707.293 10l1.414-1.414L8 14.879V0h2z' fill='%23969595' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-size: 16px 18px;
    background-repeat: no-repeat;
    background-position: right 1.5rem top 15px;
    cursor: pointer;

    @include tablet-landscape-up {
      width: grid-col(1, 2);
    }
  }

  select:disabled {
    opacity: 0.5;
  }

  @include tablet-landscape-up {
    select + select {
      margin-left: var(--grid-gutter);
    }
  }

  button[type="submit"] {
    align-self: flex-start;
    min-width: 100%;
    height: 3rem;
    padding: 0;
    border-radius: 1.5rem;
    border: none;
    outline: none;
    color: var(--color-background-darker);
    cursor: pointer;
    transition: color 0.2s;
  }

  button:hover {
    color: var(--color-hover);
  }

  h6 {
    margin-bottom: 1rem;
  }

  @include tablet-landscape-up {
    width: grid-col(8, 12);

    button[type="submit"] {
      min-width: grid-col(2, 12);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--color-text);
    -webkit-box-shadow: 0 0 0 1000px var(--color-background-darker) inset;
  }
}

.c-form__confirmation {
  position: absolute;
  width: calc(100% - 2 * var(--grid-margin));
  opacity: 0;
  pointer-events: none;
}

.c-form__title,
.c-form__heading {
  margin-bottom: 1.5rem;
}

.c-form__heading {
  margin-top: 3rem;
}

.c-form__checkbox {
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  label::before {
    content: "";
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border: 2px solid var(--color-text);
    border-radius: 50%;
    transition: border 0.2s var(--animation-ease), background-color 0.3s;
  }
}

.c-form__checkbox input[type="checkbox"]:checked + label::before,
.c-form__checkbox input[type="radio"]:checked + label::before {
  border-width: 1.5rem;
  background-color: var(--color-text);
}

.c-form__row {
  width: 100%;
}

// Flatpickr overrides

.flatpickr-current-month span.cur-month {
  font-weight: normal;
}

.flatpickr-day.selected,
.flatpickr-day.selected:hover {
  background: var(--color-background-darker);
  border-color: var(--color-background-darker);
}

.flatpickr-day.today {
  border-color: var(--color-background);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--color-background);
  background: var(--color-background);
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: var(--color-hover);
}
