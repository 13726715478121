.c-featured {
  width: 100%;
  padding: 0 var(--grid-margin);

  a {
    color: var(--color-text);
    text-decoration: none;
  }

  @include tablet-landscape-up {
    display: flex;
    justify-content: space-between;

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  }
}

.c-featured__item {
  display: block;
  width: 100%;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include tablet-landscape-up {
    margin-bottom: 0;

    &:first-child {
      width: grid-col(8, 12);
    }

    &:last-child {
      width: grid-col(4, 12);
    }
  }
}

.c-featured__image__mediawrapper,
.c-featured__item__desc {
  display: block;
}

.c-featured__image__mediawrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 1rem;
  pointer-events: none;

  @include tablet-landscape-up {
    margin-bottom: 1.5rem;
  }
}

@include tablet-landscape-up {
  .c-featured__image__mediawrapper--small {
    padding-top: 115.5%;
  }
}

.c-featured__item__media {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  background-color: var(--color-background-darker);
  pointer-events: all;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);

    @supports (object-fit: cover) {
      object-fit: cover;
      height: 100%;
    }
  }
}

@include tablet-landscape-up {
  .c-featured__item:first-child .c-featured__item__desc {
    max-width: grid-col(6, 8);
  }
}

.c-featured__item__title {
  margin-bottom: 0.5em;
}

.c-featured__item__teaser {
  display: none;
  margin-bottom: 0.75em;

  @include tablet-landscape-up {
    display: inherit;
  }
}

.c-featured__item__link {
  display: block;
  position: relative;
  padding-left: 1.4em;
  transition: color 0.2s;

  &:hover {
    color: var(--color-hover);
  }
}

.c-featured__item__desc:hover + .c-featured__item__link {
  color: var(--color-hover);
}

.c-featured__image__mediawrapper:hover + .c-featured__item__desc + .c-featured__item__link {
  color: var(--color-hover);
}
