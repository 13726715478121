.c-slideshow {
  display: flex;
  position: relative;
  padding: 0 var(--grid-margin);
}

.c-slideshow__media {
  position: relative;
  width: 100%;
  height: 100%;

  @include tablet-landscape-up {
    width: grid-col(8, 12);
  }
}

.c-slideshow__optout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-slideshow__optout__content {
  width: 100%;
  max-width: 24ch;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    hyphens: none;
    font-size: var(--fontsize-small);
  }

  @include tablet-portrait-up {
    max-width: 36ch;

    p {
      font-size: var(--fontsize-body);
    }
  }
}

.c-slideshow__optout__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0 2em;
  height: 3rem;
  border: 2px solid var(--color-text);
  border-radius: 2rem;
  font-size: var(--fontsize-small);
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}

.c-slideshow__controls {
  display: block;
}

.c-slideshow__controls__prev,
.c-slideshow__controls__next {
  position: absolute;
  bottom: -2rem;
  z-index: 2;

  @include tablet-landscape-up {
    bottom: inherit;
    top: 50%;
    transform: translateY(calc(-50% + 15px));
    opacity: 0;
    transition: opacity 0.2s, transform 0.2s var(--animation-ease);
    cursor: pointer;
  }
}

@supports (-ms-ime-align: auto) {
  .c-slideshow__controls__prev,
  .c-slideshow__controls__next {
    opacity: 1;
    transform: translateY(-50%);
  }
}

.c-slideshow__controls__prev {
  svg {
    transform: rotateY(180deg);
  }

  @include tablet-landscape-up {
    left: 1.5rem;
  }
}

.c-slideshow__controls__next {
  left: 3rem;

  @include tablet-landscape-up {
    left: inherit;
    right: 1.5rem;
  }
}

@include tablet-landscape-up {
  .c-slideshow__media:hover .c-slideshow__controls__next,
  .c-slideshow__media:hover .c-slideshow__controls__prev {
    opacity: 1;
    transform: translateY(-50%);
  }
}

.flickity-page-dots {
  text-align: right;

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    background-color: var(--color-text);
  }

  @include tablet-landscape-up {
    display: none;
  }
}

.c-slideshow__info {
  display: none;

  @include tablet-landscape-up {
    display: flex;
    flex-direction: column;
    width: grid-col(2, 12);
    margin-left: var(--grid-gutter);
  }
}

.c-slideshow__info__counter {
  margin-top: -0.18em;
  font-size: var(--fontsize-h4);
  line-height: 1;
}

.c-slideshow__info__captions {
  position: relative;
  flex: 1;

  p,
  h6 {
    font-size: var(--fontsize-small);
  }

  h6 {
    text-transform: none;
  }
}

.c-slideshow__info__caption {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s var(--animation-ease);
}
