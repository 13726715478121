.c-cookiebot {
  display: flex;
  width: 100%;
  padding: 0 var(--grid-margin);
}

.c-cookiebot__content {
  width: 100%;

  @include tablet-landscape-up {
    width: grid-col(6, 12);
  }
}
