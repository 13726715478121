.c-cover {
  position: relative;
  width: 100%;

  &:first-child {
    margin-top: -7.5rem;
  }

  @include tablet-landscape-up {
    height: 100vh;
  }
}

.c-cover__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover"; // polyfill
  }

  @include tablet-landscape-up {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    padding-top: 0;
    height: 100%;
  }
}

.c-cover__back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 56.25vw;
  left: var(--grid-margin);
  font-size: var(--fontsize-small);
  transform: translateY(-200%);
  transition: color 0.2s;

  &:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    top: 7.5rem;
    transform: translateY(0);
  }
}

.c-cover__back__icon {
  transform-origin: center center;
  transform: rotate(180deg);
  margin-right: 0.5rem;
}

.c-cover__summary {
  padding: 0 var(--grid-margin);

  @include tablet-landscape-up {
    position: absolute;
    left: var(--grid-margin);
    bottom: var(--grid-margin);
    width: grid-col(4, 12);
    padding: 0;
  }
}

.c-cover__summary__title {
  margin-bottom: 1.5rem;
  font-size: var(--fontsize-h4);
  line-height: var(--lineheight-h4);
}

.c-cover__summary__teaser {
  p {
    margin-bottom: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    color: var(--color-background);
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }
}

.c-cover__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);

  @include tablet-landscape-up {
    padding-top: 0;
    height: 100vh;
  }
}

.c-cover__summary__type--soldout {
  color: var(--color-red);
}
