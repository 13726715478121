.c-schedule {
  padding: 0 var(--grid-margin);
}

.c-schedule__heading {
  margin-bottom: 1.5rem;
}

.c-schedule__desc {
  width: 100%;
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 1em;
  }

  @include tablet-landscape-up {
    width: grid-col(4, 12);
  }
}

.c-schedule__event {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 2px solid var(--color-text);

  &:first-child {
    border-top: 2px solid var(--color-text);
  }

  a {
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    align-items: center;
    height: 6rem;
    padding: 0;
  }
}

.c-schedule__event__time {
  width: grid-col(4, 12);
}

.c-schedule__event__title {
  width: grid-col(8, 12);
}

.c-schedule__event__empty {
  width: 100%;
}

p.c-schedule__event__title {
  padding-left: 1.4em;
}
