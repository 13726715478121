.c-opening_hours {
  padding: 0 var(--grid-margin);
}

.c-opening_hours__title {
  margin-bottom: 1.5rem;
}

.c-opening_hours__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1.875rem;

  @include tablet-portrait-up {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--grid-gutter);
  }

  @include tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.c-opening_hours__item__date {
  margin-bottom: 0.25em;
  font-size: var(--fontsize-body);
}
