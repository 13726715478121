.c-booking {
  display: none;

  @include tablet-landscape-up {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--grid-margin);
    width: 100%;
    height: 100px;
    font-size: var(--fontsize-small);
    z-index: z-index(content);
    transition: background-color var(--animation-duration-medium);

    &.is-fixed {
      background-color: #000;
      z-index: z-index(toolbar);
    }
  }
}

.c-booking__title {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--animation-duration-short) var(--animation-ease);
}

.is-fixed .c-booking__title {
  opacity: 1;
}

.c-booking__datepicker {
  display: flex;
}

.c-booking__dates,
.c-booking__times,
.c-booking__buy {
  position: absolute;
  top: 50%;
  right: var(--grid-margin);
  transform: translateY(-50%);
}

.c-booking__dates__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 13rem;
  height: 3rem;
  padding: 0 1.5rem;
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  text-transform: capitalize;
  cursor: pointer;
  z-index: 2;

  svg {
    width: 1.125rem;
    height: 1.1875rem;
    fill: var(--color-background);
    transform-origin: center center;
    transform: rotate(-180deg);
    transition: transform 0.3s;
  }
}

.is-open .c-booking__dates svg {
  transform: rotate(0);
}

.c-booking__dates__list {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem 2rem 0 0;
  user-select: none;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2rem;
    pointer-events: none;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2rem;
    pointer-events: none;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
  }

  ul {
    padding-bottom: 2rem;
  }
}

.c-booking__dates__scrollcontainer {
  width: calc(100% + 20px);
  height: 19rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.c-booking__dates__track {
  position: absolute;
  top: 2rem;
  right: 8px;
  bottom: 2rem;
  width: 2px;
  background-color: var(--color-track);
  z-index: 2;
}

.c-booking__dates__scrollbar {
  position: absolute;
  top: 2rem;
  right: 8px;
  width: 2px;
  background-color: var(--color-background-darker);
  z-index: 2;
}

.is-fixed .c-booking__dates__list {
  bottom: unset;
  padding-top: 1.5rem;
  top: 1.5rem;
  border-radius: 0 0 2rem 2rem;

  &::before {
    top: 1.5rem;
    height: 2rem;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
  }

  &::after {
    bottom: 0;
    height: 2rem;
    background-image:
      linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
  }
}

.c-booking__dates__item {
  padding: 1.25rem 1.5rem;
  transition: color 0.3s;
  overflow: hidden;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }

  input[type="radio"] {
    position: absolute;
    width: 0;
    top: 0;
    left: -5000px;
    opacity: 0;
  }

  label {
    cursor: pointer;
  }
}

.c-booking__times {
  display: flex;
}

.c-booking__time {
  position: relative;
  display: none;
  margin-left: 0.5rem;

  .c-booking__time__link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    min-width: 5.125rem;
    height: 3rem;
    border: 2px solid var(--color-text);
    border-radius: 2rem;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
  }

  .c-booking__time__link:hover {
    color: var(--color-background);
  }

  .c-booking__time__label--soldout:hover {
    color: var(--color-text);
  }

  &.is-visible {
    display: block;
  }
}

.c-booking__buy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.625rem;
  height: 3rem;
  margin-left: 0.5rem;
  font-size: var(--fontsize-small);
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  border: none;
  transition: color 0.3s, opacity 0.3s;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    color: var(--color-background);
  }
}
