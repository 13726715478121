.c-article {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--grid-margin);
}

.c-article__title {
  width: 100%;
  margin-bottom: 1.5rem;
}

.c-article__text,
.c-article__notes {
  a {
    color: var(--color-hover);
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-text);
  }
}

.c-article__text {
  width: 100%;

  p {
    margin-bottom: 1em;
  }

  h5 {
    font-size: var(--fontsize-body);
    margin-bottom: 0.5rem;
  }

  @include tablet-landscape-up {
    width: grid-col(6, 12);
  }
}

.c-article__notes {
  width: 100%;
  margin-top: 1.5rem;

  h5,
  p {
    font-size: var(--fontsize-small);
  }

  h5 {
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1em;
  }

  @include tablet-landscape-up {
    width: grid-col(2, 12);
    margin-left: var(--grid-gutter);
    margin-top: 0;
  }
}
