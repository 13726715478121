.c-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 var(--grid-margin);

  p {
    font-size: var(--fontsize-small);
  }

  @include tablet-landscape-up {
    p {
      margin-bottom: 0.5em;
    }
  }
}

.c-images__item {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-child {
    margin-bottom: 1.5rem;
  }

  @include tablet-landscape-up {
    &:first-child {
      width: grid-col(8, 12);
      margin-bottom: 0;
    }

    &:last-child {
      width: grid-col(4, 12);
    }
  }
}

.c-images__item__media + .c-images__item__caption {
  margin-top: 0.5rem;
}
