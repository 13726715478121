.c-timetable {
  display: flex;
  position: fixed;
  width: 100vw;
  padding: 1.5rem var(--grid-margin) 2rem;
  background-color: var(--color-text);
  color: var(--color-background);
  will-change: transform;
  transform: translateY(-101%);

  @include z-index(navigation);

  &.is-visible {
    transform: translateY(0);
  }
}

.c-timetable__week {
  width: grid-col(6, 12);
  margin-right: var(--grid-gutter);
}

.c-timetable__general {
  width: grid-col(5, 12);
}

.c-timetable__week__heading,
.c-timetable__general__heading {
  margin-bottom: 0.75em;
}

.c-timetable__week__item {
  display: flex;
  justify-content: space-between;
}

.c-timetable__week__day {
  width: grid-col(2, 6);
}

.c-timetable__week__time {
  width: grid-col(4, 6);

  span {
    margin-left: 0.25em;
    font-size: 0.5em;
    vertical-align: super;
  }
}

.c-timetable__week__notes {
  margin-top: 1.5rem;
  font-size: var(--fontsize-small);

  span {
    margin-right: 0.25em;
    font-size: 0.75em;
    vertical-align: super;
  }
}

.c-timetable__week__note {
  margin-bottom: 0.5em;
}

.c-timetable__general__hours,
.c-timetable__general__info {
  margin-bottom: 1.5rem;
}

.c-timetable__general__link {
  color: var(--color-background);
}

.c-timetable__close {
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
}
