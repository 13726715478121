.c-triple {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 var(--grid-margin);

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: var(--grid-gutter);
    justify-items: stretch;
  }

  @include tablet-landscape-up {
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: nowrap;
  }
}

.c-triple__column {
  margin-bottom: 3rem;

  a {
    transition: color 0.2s;
  }

  a:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    margin-bottom: 0;
  }
}

.c-triple__column__title {
  font-size: var(--fontsize-h4);
  margin-bottom: 1.25rem;
}

.c-triple__column__text {
  margin-bottom: 1.25rem;
}
