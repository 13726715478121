.c-giftcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 var(--grid-margin) 1rem;
  padding: 0.5rem;
  background-color: var(--color-text);
  color: var(--color-background);
  border-radius: 25rem;
  transition: color 0.4s;

  &:hover {
    color: var(--color-hover);
  }

  &:last-of-type {
    margin-bottom: 4rem;
  }

  @include tablet-landscape-up {
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 5.5rem;
    }
  }
}

.c-giftcard__title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: calc(var(--grid-gutter) * 2);

  h2 {
    font-size: var(--fontsize-h1);
    margin-bottom: 0.125em;
    line-height: 1;
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  @include tablet-landscape-up {
    width: grid-col(8, 12);

    svg {
      width: 9rem;
      height: 9rem;
      margin-right: 1.5rem;
    }
  }
}

.c-giftcard__desc {
  display: none;

  @include tablet-landscape-up {
    display: block;
    width: grid-col(4, 12);
  }
}
