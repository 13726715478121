.ls-blur-up-img {
  filter: blur(10px);
  opacity: 1;
  transition: opacity 1000ms, filter 1500ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded,
.lazyloaded .ls-blur-up-img {
  opacity: 0;
  filter: blur(5px);
}
