@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.c-stream {
  display: none;

  @include tablet-landscape-up {
    display: block;
    width: 100%;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-stream__info {
  position: absolute;
  top: 1rem;
  left: var(--grid-margin);
  width: grid-col(4, 12);
  z-index: 2;

  @include tablet-landscape-up {
    top: 1.5rem;
  }
}

.c-stream__info__title {
  display: none;

  @include tablet-landscape-up {
    width: 100%;
    display: inherit;
    margin-bottom: 1.5rem;
  }
}

.c-stream__info__desc {
  display: none;

  @include tablet-landscape-up {
    width: 100%;
    display: inline;
  }
}

.c-stream__info__label {
  color: var(--color-red);
  animation: pulse 2.5s infinite;
  font-size: var(--fontsize-body);
}

.c-stream__player {
  pointer-events: none;
}

.u-stream {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; // 16:9
  overflow: hidden;
  background-color: var(--color-background-darker);

  img,
  video,
  picture,
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 115%;
    height: 115%;
    transform: translate(-50%, -50%);
  }
}

.c-stream__optout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.c-stream__optout__content {
  width: 100%;
  max-width: 24ch;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    hyphens: none;
    font-size: var(--fontsize-small);
  }

  @include tablet-portrait-up {
    max-width: 36ch;

    p {
      font-size: var(--fontsize-body);
    }
  }
}

.c-stream__optout__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0 2em;
  height: 3rem;
  border: 2px solid var(--color-text);
  border-radius: 2rem;
  font-size: var(--fontsize-small);
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}
