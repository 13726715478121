.c-tabs__navigation {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0 var(--grid-margin);

  @include tablet-landscape-up {
    flex-direction: row;
    margin-bottom: 2.5rem;
  }
}

.c-tabs__navigation__item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;

  &::before {
    content: "";
    box-sizing: border-box;
    display: block;
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border: 2px solid var(--color-text);
    border-radius: 50%;
    transition: border 0.2s var(--animation-ease), background-color 0.3s;
  }

  &.is-active::before {
    border-width: 1.5rem;
    background-color: var(--color-text);
  }

  @include tablet-landscape-up {
    margin-bottom: 0;

    &::before {
      margin-right: 1rem;
    }
  }
}

@include tablet-landscape-up {
  .c-tabs__navigation__item + .c-tabs__navigation__item {
    margin-left: 3rem;
  }
}

.c-tabs__tabgroup {
  position: relative;
  width: 100%;
}

.c-tabs__tabgroup__content {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  max-height: 0;
  overflow-y: hidden;
  transition: opacity 0.2s var(--animation-ease);
  pointer-events: none;

  &.is-animating {
    display: block;
    position: relative;
    max-height: inherit;
    overflow-y: visible;
  }

  &.is-active {
    display: block;
    max-height: inherit;
    overflow-y: visible;
    position: relative;
    opacity: 1;
    pointer-events: all;
  }
}
