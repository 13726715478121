:root {
  --color-background: #969595;
  --color-background-darker: #6e6e6e;
  --color-text: #fff;
  --color-hover: #d5d4d4;
  --color-black: #000;
  --color-red: #f00;
  --color-track: #e4e4e4;
  --fontfamily-body: "Sono Regular", Helvetica, Arial, sans-serif;
  --fontsize-small: 0.875rem;
  --fontsize-body: 1.25rem;
  --fontsize-huge: 12.5rem;
  --fontsize-h1: 2rem;
  --fontsize-h2: 4.25rem;
  --fontsize-h3: 3.625rem;
  --fontsize-h4: 2rem;
  --fontsize-h5: 2rem;
  --lineheight-small: 1.1429;
  --lineheight-body: 1.2;
  --lineheight-tiny: 0.9;
  --lineheight-h1: 1.0435;
  --lineheight-h2: 1.0588;
  --lineheight-h3: 1.1034;
  --lineheight-h4: 1.0909;
  --lineheight-h5: 1.125;
  --lineheight-tall: 1.5;
  --grid-cols: 12;
  --grid-gutter: 1.666666667vw;
  --grid-margin: 1rem;
  --animation-duration-short: 0.2s;
  --animation-duration-medium: 0.4s;
  --animation-duration-long: 0.6s;
  --animation-ease: cubic-bezier(0.645, 0.045, 0.355, 1);

  @include tablet-landscape-up {
    --fontsize-small: 0.875rem; // 14px
    --fontsize-body: 1.25rem; // 20px
    --fontsize-h1: 5.75rem; // 92px
    --fontsize-h2: 4.25rem; // 68px
    --fontsize-h3: 3.625rem; // 58px
    --fontsize-h5: 2rem; // 32px
    --fontsize-huge: 16.5vw; // 240px at 1440 width
    --grid-margin: 1.5rem;
  }

  @include desktop-up {
    --fontsize-h4: 2.75rem; // 44px
  }
}

$z-index: (
  modal: 70,
  toggle: 60,
  navigation: 50,
  overlay: 40,
  toolbar: 30,
  menu: 20,
  content: 10
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
