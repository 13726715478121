.c-related {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 var(--grid-margin);
}

.c-related__title {
  width: 100%;
  margin-bottom: 1.5rem;

  @include tablet-landscape-up {
    width: grid-col(4, 12);
    margin-right: grid-col(8, 12);
  }
}

.c-related__item {
  width: 100%;

  @include tablet-landscape-up {
    width: grid-col(4, 12);
  }
}

.c-related__item__media {
  margin-bottom: 1.5rem;
}

.c-related__info {
  margin-bottom: 3rem;
  transition: color 0.2s;

  @include tablet-landscape-up {
    margin-bottom: 0;
  }
}

.c-related__info:hover {
  color: var(--color-hover);
}
