.c-video {
  display: flex;
  padding: 0 var(--grid-margin);
}

.c-video__media {
  width: 100%;

  @include tablet-landscape-up {
    width: grid-col(8, 12);
  }
}

.c-video__info {
  display: none;

  p,
  h6 {
    font-size: var(--fontsize-small);
  }

  h6 {
    text-transform: none;
  }

  @include tablet-landscape-up {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: grid-col(2, 12);
    margin-left: var(--grid-gutter);
  }
}

.c-video__optout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-video__optout__content {
  width: 100%;
  max-width: 24ch;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    hyphens: none;
    font-size: var(--fontsize-small);
  }

  @include tablet-portrait-up {
    max-width: 36ch;

    p {
      font-size: var(--fontsize-body);
    }
  }
}

.c-video__optout__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0 2em;
  height: 3rem;
  border: 2px solid var(--color-text);
  border-radius: 2rem;
  font-size: var(--fontsize-small);
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}
