.c-reservation-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1em var(--grid-margin);
  font-size: var(--fontsize-small);
  background-color: var(--color-black);
  z-index: z-index(navigation);

  @include tablet-landscape-up {
    display: none;
  }
}

.c-reservation-mobile__text {
  font-size: var(--fontsize-small);
  width: 60%;
}

.c-reservation-mobile__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.5rem;
  height: 3rem;
  margin-left: 0.5rem;
  font-size: var(--fontsize-small);
  background-color: var(--color-text);
  color: var(--color-background-darker);
  border-radius: 2rem;
  transition: color 0.3s, opacity 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-background);
  }
}
