.u-media {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; // 16:9
  overflow: hidden;
  background-color: var(--color-background-darker);

  img,
  video,
  picture,
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    transform: translate(-50%, -50%);

    @supports (object-fit: cover) {
      object-fit: cover;
      height: 100%;
    }
  }
}

.u-link {
  display: block;
  position: relative;
  padding-left: 1.4em;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0.15em;
    width: 1em;
    height: 1em;
    background-color: var(--color-text);
    border-radius: 50%;
  }
}

.u-link--dark {
  &::before {
    background-color: var(--color-background);
  }
}

.u-small {
  font-size: var(--fontsize-small);
  line-height: var(--lineheight-small);
}
