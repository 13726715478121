.c-notice {
  width: calc(100% - var(--grid-margin) * 2);
  padding: 1rem 1rem 0.95rem 1rem;
  position: fixed;
  left: var(--grid-margin);
  bottom: var(--grid-margin);
  background-color: var(--color-text);
  color: var(--color-background);
  z-index: z-index(modal);
  opacity: 0;
  pointer-events: none;
  box-shadow:
    0 0 0.5px rgba(0, 0, 0, 0.035),
    0 0 1.3px rgba(0, 0, 0, 0.05),
    0 0 3px rgba(0, 0, 0, 0.065),
    0 0 10px rgba(0, 0, 0, 0.1);

  @include tablet-landscape-up {
    width: grid-col(4, 12);
  }
}

.c-notice__heading {
  margin-bottom: 0.75em;
}

.c-notice__text {
  max-width: 85%;
  hyphens: none;
  margin-bottom: 0.75rem;
}

.c-notice__link {
  color: var(--color-background);
  transition: color 0.2s;
  font-size: var(--fontsize-small);

  &:hover {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    font-size: inherit;
  }
}

.c-notice__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 52px;
  cursor: pointer;

  svg {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
