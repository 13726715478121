.c-intro {
  padding: 0 var(--grid-margin);
}

.c-intro__heading {
  font-size: var(--fontsize-small);
  text-transform: uppercase;
}

.c-intro__text {
  font-size: var(--fontsize-h4);
  line-height: var(--lineheight-h4);

  @include tablet-landscape-up {
    width: grid-col(8, 12);
  }
}

.c-intro__link {
  margin-top: 1.5rem;
}
