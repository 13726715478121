.c-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  max-height: 100%;
  transform-origin: center center;
  z-index: -1;
}
