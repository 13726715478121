.c-accordion {
  width: 100%;
  padding: 0 var(--grid-margin);
}

.c-accordion__intro__title {
  margin-left: -0.07em;
  margin-bottom: 1rem;
  font-size: var(--fontsize-h3);
  hyphens: none;

  @include tablet-landscape-up {
    margin-bottom: 2.5rem;
    font-size: var(--fontsize-huge);
    line-height: var(--lineheight-tiny);
  }
}

.c-accordion__intro__desc {
  margin-bottom: 2rem;

  @include tablet-landscape-up {
    width: grid-col(4, 12);
    margin-bottom: 3rem;
  }
}

.c-accordion__item {
  border-top: 2px solid var(--color-text);

  &:last-child {
    border-bottom: 2px solid var(--color-text);
  }

  @include tablet-landscape-up {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0.5rem;
  }
}

.c-accordion__item__title {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.7rem;

  @include tablet-landscape-up {
    h4 {
      // max-width: grid-col(1, 2);
      cursor: pointer;
    }
  }
}

.c-accordion__item__icon {
  align-self: flex-start;
  transform: translateY(0.2rem) rotate(-180deg);
  transform-origin: center center;
  transition: transform 0.4s var(--animation-ease);
  cursor: pointer;
}

.is-open .c-accordion__item__icon {
  transform: translateY(0.2rem) rotate(0);
}

.c-accordion__item__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  max-height: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  opacity: 0;
}

.c-accordion__item__media {
  width: 100%;
  margin-top: 1rem;

  @include tablet-landscape-up {
    width: grid-col(8, 12);
    margin-top: 1rem;
  }
}

.c-accordion__item__text {
  width: 100%;
  margin-top: 1rem;
  margin-left: var(--grid-gutter);

  p {
    margin-bottom: 1em;
  }

  a {
    color: var(--color-hover);
  }

  @include tablet-landscape-up {
    width: grid-col(4, 12);
  }
}
